<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <v-card>
          <v-row dense>
            <v-col cols="12" sm="6" md="2"> Code : {{ invent.code }} </v-col>
            <v-col cols="12" sm="6" md="2">
              Responsable : {{ invent.pilot_name }}
            </v-col>
            <v-col cols="12" sm="6" md="2">
              Date Inventaire : {{ invent.date_inv }}
            </v-col>
            <v-col cols="12" sm="6" md="2">
              Valeur Physique :
              {{
                numberWithSpace(
                  invent.tot_val_phy ? invent.tot_val_phy.toFixed(2) : "0.00"
                )
              }}
            </v-col>
            <v-col cols="12" sm="6" md="2">
              Valeur Comptable :
              {{
                numberWithSpace(
                  invent.tot_val_cmpt ? invent.tot_val_cmpt.toFixed(2) : "0.00"
                )
              }}
            </v-col>
            <v-col cols="12" sm="6" md="2">
              Ecart :
              <span class="ecart">
                {{
                  numberWithSpace(
                    invent.tot_val_ecart
                      ? invent.tot_val_ecart.toFixed(2)
                      : "0.00"
                  )
                }}
              </span>
            </v-col>
          </v-row>

          <v-card-title>
            <v-select
              :items="allinvs"
              v-model="invent"
              :item-text="(item) => item.code + '  (' + item.statut_name + ')'"
              chips
              label="Inventaires"
              return-object
              @change="DataLoad"
            >
            </v-select>
            <v-btn
              color="blue darken-1"
              @click="newinv"
              class="ma-2"
              fab
              :disabled="add_disabled"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
            <v-btn color="blue darken-1" @click="OpenInventForm" class="ma-2">
              Inventaire
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click="gest_depot"
              class="ma-2"
              :disabled="loading"
            >
              Gestion des Depots
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              @click="print_3"
              class="ma-2"
              v-if="invent.nbcompte == 2"
            >
              Liste 3eme Comptage
            </v-btn>
            <v-btn color="blue darken-1" @click="ecart" class="ma-2">
              Liste Ecarts
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              @click="annuler"
              v-if="
                invent.statut_id == '18' &&
                ($store.state.auth.includes('06028') || $store.state.isadmin)
              "
              class="ma-2"
            >
              Annuler
              <v-icon dark right> mdi-cancel </v-icon>
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click="valid"
              v-if="invent.statut_id == '18'"
              class="ma-2"
            >
              Valider
              <v-icon dark right> mdi-check-bold </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="6" md="12">
                <v-select
                  :items="depots_list"
                  v-model="depots_select"
                  item-text="depot_name"
                  item-value="depot_id"
                  chips
                  label="Filtre"
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index <= 5">
                      <span>{{ item.depot_name }}</span>
                    </v-chip>
                    <span v-if="index === 6" class="grey--text text-caption">
                      (+{{ depots_select.length - 6 }} autres)
                    </span>
                  </template>
                  <!-- Add a tile with Select All as Lalbel and binded on a method that add or remove all items -->
                  <v-list slot="prepend-item" ripple>
                    <v-list-item @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            depots_select.length > 0 ? 'indigo darken-4' : ''
                          "
                          >{{ icon }}</v-icon
                        >
                      </v-list-item-action>

                      <v-list-item-title>Tous</v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-divider slot="prepend-item" class="mt-2" />
                </v-select>
              </v-col>
            </v-row>
            <v-col cols="12" sm="6" md="12">
              <listitems
                :list="list_items"
                :title="'Detail Inventaire'"
                :qDelete="Qproductdelete"
                :headers="detail_headers"
                :key="klist"
                :showedit="false"
                :master="true"
                :add="invent.statut_id == '18'"
                :del="invent.statut_id == '18'"
                :filename="'Inventaire'"
                :sheetname="'Produits'"
                @open="OpenDetailForm"
                @rowselect="DetailChange"
                :Total="true"
                :ligne="ligne"
                :laoding="loading"
              >
              </listitems>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <inventform
      :item="invent"
      :agents_list="agents_list"
      :alldepots="alldepots"
      :key="keyinvent"
      :showForm="!isInventClosed"
      :dernier_inv="dernier_inv"
      @close="closeInventForm"
      :cmpt_inv="cmpt_inv"
      @add="inv_add"
    >
    </inventform>
    <invdetailform
      :item="produit"
      :items="list_items"
      :depots_list="depots_list"
      :defaultdepot="depot_id"
      :nbcompte="invent.nbcompte"
      :readonly="invent.statut_id >= '19'"
      :key="kdet"
      :showForm="!isDetailClosed"
      @close="closeDetailForm"
      :products="products"
      :invent_detail="invent_detail"
      @add="newligne"
    >
    </invdetailform>
    <formlist
      :title="title"
      :list="list"
      :showform="!isListClose"
      :listheaders="listheaders"
      :key="fl"
      @close="closeListForm"
      :Total="true"
      :master="true"
      :width="width"
      :orientation="orientation"
    >
    </formlist>
    <depotlist
      :invent="invent"
      :invent_detail="invent_detail"
      :showform="!isDepotListClosed"
      :key="dl"
      :alldepots="alldepots"
      :agents_list="agents_list"
      @close="closeDepotList"
      @update="updateInvent"
    >
    </depotlist>

    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
const inventform = () => import("../components/InventForm.vue");
const invdetailform = () => import("../components/InvDetailForm.vue");
const confirmdialog = () => import("../components/ConfirmDialog.vue");
const listitems = () => import("../components/ListItems.vue");
const formlist = () => import("../components/FormList.vue");
const depotlist = () => import("../components/InvDepotList.vue");

import DATA from "../graphql/Inventaire/DATA.gql";
import INVENT from "../graphql/Inventaire/INVENT.gql";
import ALLINVENT from "../graphql/Inventaire/ALLINVENT.gql";
import DELETE_INVENT_DETAIL from "../graphql/Inventaire/DELETE_INVENT_DETAIL.gql";
import INV_DETAIL from "../graphql/Inventaire/INV_DETAIL.gql";
import STOCK_A_DATE from "../graphql/Inventaire/STOCK_A_DATE.gql";
import UPDATE_PRODUCT_DEPOT from "../graphql/Product/UPDATE_PRODUCT_DEPOT.gql";
//import UPDATE_INVENT_MULTI from "../graphql/Inventaire/UPDATE_INVENT_MULTI.gql";
import UPDATE_INVENT from "../graphql/Inventaire/UPDATE_INVENT.gql";

export default {
  components: {
    inventform,
    confirmdialog,
    listitems,
    invdetailform,
    formlist,
    depotlist,
  },

  data: () => ({
    add_disabled: false,
    orientation: "p",
    width: "1000px",
    list: [],
    title: "",
    isListClose: true,
    isDepotListClosed: true,

    fl: 0,
    dl: 100,

    ligne: 0,
    depot_id: "",
    depots_select: [],
    cmpt_inv: 1,
    dataSource: [],
    load: false,
    listheaders: [],

    detail_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Depot",
        value: "depot_name",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
      },
      {
        text: "Comptage",
        value: "qte1",
        selected: true,
        slot: "cur33",
        align: "right",
      },
      {
        text: "2eme Comptage",
        value: "qte2",
        selected: true,
        slot: "cur33",
        align: "right",
      },
      {
        text: "3eme Comptage",
        value: "qte3",
        selected: true,
        slot: "cur33",
        align: "right",
      },
      {
        text: "Stock Physique",
        value: "stock_phy",
        selected: true,
        slot: "cur33",
        align: "right",
        total: "stock_phy",
      },
      {
        text: "Stock Comptable",
        value: "stock_cmpt",
        selected: true,
        slot: "cur33",
        align: "right",
        total: "stock_cmpt",
      },
      {
        text: "Cout Unit",
        value: "pmp",
        selected: true,
        slot: "cur33",
        align: "right",
      },
      {
        text: "Ecart Qte",
        value: "qte_ecart",
        selected: true,
        slot: "cur33",
        align: "right",
        total: "qte_ecart",
      },
      {
        text: "Ecart Valeur",
        value: "val_ecart",
        selected: true,
        slot: "cur33",
        align: "right",
        total: "val_ecart",
      },
    ],
    compt3_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Depot",
        value: "depot_name",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        slot: "href",
        selected: true,
      },

      {
        text: "3eme Comptage",
        value: "qte3",
        selected: true,
        slot: "cur33",
        align: "right",
      },
    ],
    ecart_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Depot",
        value: "depot_name",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        slot: "href",
        selected: true,
      },
      {
        text: "Stock Physique",
        value: "stock_phy",
        selected: true,
        slot: "cur33",
        align: "right",
        total: "stock_phy",
      },
      {
        text: "Stock Comptable",
        value: "stock_cmpt",
        selected: true,
        slot: "cur33",
        align: "right",
        total: "stock_cmpt",
      },
      {
        text: "Cout Unit",
        value: "pmp",
        selected: true,
        slot: "cur33",
        align: "right",
      },
      {
        text: "Ecart Qte",
        value: "qte_ecart",
        selected: true,
        slot: "cur33",
        align: "right",
        total: "qte_ecart",
      },
      {
        text: "Ecart Valeur",
        value: "val_ecart",
        selected: true,
        slot: "cur33",
        align: "right",
        total: "val_ecart",
      },
    ],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    produit: {},
    dernier_inv: "",
    isInventClosed: true,
    isDetailClosed: true,
    invent: {},
    agents_list: [],
    klist: 200,
    kdet: 300,
    keyinvent: 400,
    products: [],
    alldepots: [],
    allinvs: [],
    loading: false,
    invent_detail: [],
  }),

  watch: {
    depots_select: {
      handler() {
        this.klist++;
      },
      deep: true,
    },
  },
  computed: {
    list_items() {
      let l = [];
      l = this.invent_detail.filter((elm) =>
        this.depots_select.includes(elm.depot_id)
      );
      return l;
    },
    icon() {
      if (
        this.depots_select.length === this.depots_list.length &&
        this.depots_list.length > 0
      )
        return "mdi-close-box";
      if (
        this.depots_select.length > 0 &&
        !(this.depots_select.length === this.depots_list.length)
      )
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    Qproductdelete() {
      return DELETE_INVENT_DETAIL;
    },
    depots_list() {
      return this.invent.depots ? this.invent.depots : [];
    },
  },
  created() {},

  async mounted() {
    //this.load = true;
    let r = await this.requette(DATA);
    if (r) {
      this.agents_list = r.agents;
      this.dernier_inv = r.dernier_inv;
      if (this.agents_list) this.agents_list.sort(this.sortBy("nom", 1));
      this.cmpt_inv = r.options.cmpt_inv;
      this.alldepots = r.depots;
    }

    r = await this.requette(ALLINVENT);

    await this.selectInvent();
  },

  methods: {
    async inv_add() {
      await this.updateInvent();
      this.DataLoad();
    },
    gest_depot() {
      this.dl++;
      this.isDepotListClosed = false;
    },
    async selectInvent() {
      let r = await this.requette(ALLINVENT);
      if (r) this.allinvs = r.allinventaires;
      this.allinvs.sort(this.sortBy("date_inv", -1));
      let i = this.allinvs.findIndex((elm) => elm.statut_id == "18");
      if (i >= 0) {
        this.invent = this.allinvs[i];
        this.add_disabled = true;
      } else {
        if (this.allinvs.length > 0) this.invent = this.allinvs[0];
        else this.invent = {};
        this.add_disabled = false;
      }
      this.DataLoad();
    },
    async updateInvent() {
      let r = await this.requette(INVENT);
      if (r) if (r.allinventaires.length > 0) this.invent = r.allinventaires[0];
    },
    closeDepotList(ligne) {
      if (ligne >= 0) {
        this.ligne = ligne;
        this.klist++;
      }
      this.isDepotListClosed = true;
    },
    pad(n) {
      return n < 10 ? "0" + n : n;
    },
    datetime() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        this.pad(today.getMonth() + 1) +
        "-" +
        this.pad(today.getDate());
      var time =
        this.pad(today.getHours()) +
        ":" +
        this.pad(today.getMinutes()) +
        ":" +
        this.pad(today.getSeconds());
      return date + " " + time;
    },
    closeListForm() {
      this.isListClose = true;
    },
    ecart() {
      this.width = "1400px";
      this.title = "Etat des Ecarts ";
      this.orientation = "l";
      this.listheaders = this.ecart_headers;
      this.list = this.invent_detail.filter(
        (elm) =>
          elm.stock_phy != null &&
          elm.stock_cmpt != null &&
          elm.stock_phy != elm.stock_cmpt &&
          this.depots_select.includes(elm.depot_id)
      );
      this.fl++;
      this.isListClose = false;
    },
    print_3() {
      this.width = "1000px";
      this.title = "Confirmation Liste ";
      this.orientation = "p";
      this.listheaders = this.compt3_headers;
      this.list = this.invent_detail.filter(
        (elm) =>
          elm.qte1 != null &&
          elm.qte2 != null &&
          elm.qte1 != elm.qte2 &&
          this.depots_select.includes(elm.depot_id)
      );
      this.fl++;
      this.isListClose = false;
    },
    newligne(index) {
      this.ligne = index;
      this.klist++;
    },
    async annuler() {
      if (
        await this.$refs.confirm.open(
          "Inventaire",
          "Etês-vous sûr de vouloir annuler cet inventaire"
        )
      ) {
        this.invent.statut_id = "20";

        let v = {
          invent: {
            id: this.invent.id,
            statut_id: this.invent.statut_id,
            write_uid: parseInt(this.$store.state.me.id),
          },
        };
        this.maj(UPDATE_INVENT, v, true);
      }
    },

    async valid() {
      let ok = true;

      // await this.dataGrid.saveEditData();

      //verifier que tout les depots sont a statut==3
      if (ok) {
        for (let index = 0; index < this.depots_list.length; index++) {
          const element = this.depots_list[index];
          if (element.statut_id != "3") {
            ok = false;
            this.snackbar_text =
              "Verifier le depot : " +
              element.depot_name +
              " statut : " +
              element.statut_name;
            this.snackbar_color = "error";
            this.snackbar = true;
            break;
          }
          //verifier responsable explication
          if (element.expl_id == null) {
            ok = false;
            this.snackbar_text =
              "Selectionner le responsable des explications des ecarts pour le depot " +
              element.depot_name;
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
      }
      let i;
      //verifier tous les articles
      if (!this.invent.type_inv && ok) {
        for (let index = 0; index < this.products.length; index++) {
          const element = this.products[index];
          if (element.depot_id != null)
            i = this.invent_detail.findIndex(
              (elm) =>
                elm.produit_id == element.id && elm.depot_id == element.depot_id
            );
          else
            i = this.invent_detail.findIndex(
              (elm) => elm.produit_id == element.id
            );
          if (i < 0) {
            ok = false;
            if (element.depot_id != null)
              this.snackbar_text =
                element.label + " Depot " + element.depot_name + " manquant!!";
            else this.snackbar_text = element.label + " manquant!!";
            this.snackbar_color = "error";
            this.snackbar = true;
            break;
          }
        }
      }

      if (ok)
        if (
          await this.$refs.confirm.open(
            "Inventaire",
            "Etês-vous sûr de vouloir valider cet inventaire"
          )
        ) {
          //   this.dataGrid.saveEditData();
          this.invent.statut_id = "19";
          let v = {
            invent: {
              id: this.invent.id,
              statut_id: this.invent.statut_id,
            },
          };
          this.loading = true;
          await this.maj(UPDATE_INVENT, v, true);

          v = {
            inv_id: this.invent.id,
            user_id: this.$store.state.me.id,
            date_time: this.datetime(),
          };
          this.maj(UPDATE_PRODUCT_DEPOT, v);
          this.detail_headers[3].slot = null;
          this.invent = {};
          this.loading = false;
        }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.depots_select.length === this.depots_list.length) {
          this.depots_select = [];
        } else {
          this.depots_select = this.depots_list.map((itemY) => {
            return itemY.depot_id;
          });
        }
      });
      this.klist++;
    },
    async requette(query, v) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    sortBy(prop) {
      return function (a, b, s) {
        return s == 1
          ? a[prop].localeCompare(b[prop])
          : b[prop].localeCompare(a[prop]);
      };
    },

    async DataLoad() {
      this.detail_headers[5].hiden = this.invent.nbcompte == 1;
      this.detail_headers[6].hiden = this.invent.nbcompte == 1;
      this.depots_select = [];
      this.depots_list.forEach((element) => {
        this.depots_select.push(element.depot_id);
      });
      if (this.invent.id > 0) {
        this.loading = true;
        let v = {
          inv_id: this.invent.id,
        };
        let r = await this.requette(INV_DETAIL, v);
        if (r) this.invent_detail = r.inv_details;
        this.klist++;
        this.loading = false;
        this.detail_headers[3].slot = "href";
        v = {
          date_stk: this.invent.date_inv,
        };
        r = await this.requette(STOCK_A_DATE, v);
        if (r) this.products = r.stockadate;
      }
    },
    newinv() {
      this.invent.id = -1;
      this.isInventClosed = false;
      this.keyinvent++;
    },
    OpenInventForm() {
      this.isInventClosed = false;
      this.keyinvent++;
    },
    closeInventForm(item) {
      this.invent = item;
      this.detail_headers[5].hiden = this.invent.nbcompte == 1;
      this.detail_headers[6].hiden = this.invent.nbcompte == 1;
      this.isInventClosed = true;
    },
    DetailChange(item) {
      this.produit = item;
    },
    OpenDetailForm(item) {
      if (this.depot_id == "" && this.depots_select.length == 1)
        this.depot_id = this.depots_select[0];
      this.produit = item;
      this.produit.inv_id = this.invent.id;
      this.isDetailClosed = false;
      this.kdet++;
    },
    closeDetailForm(item) {
      this.depot_id = item.depot_id;
      this.isDetailClosed = true;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>
